import React, {useState, useEffect} from 'react'
import propTypes from 'prop-types'
import {ReactSVG} from 'react-svg';
import searchIcon from '../../../assets/icons/search-white.svg';
import closeIcon from '../../../assets/icons/close-white.svg';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import {theme} from '../../../themes/default-theme';
import {Button} from '@material-ui/core';

const InputContainer = styled.div`
  position: absolute;
  width: calc(100% - 80px);
  top: 15px;
  left: 0;

  ${theme.breakpoints.down('sm')} {
    width: calc(100% - 40px);
    top: 0;
  }
`
const Input = styled(TextField)`
  width: 100%;
  
  input {
    padding-left: 24px;
    padding-right: 60px;
    background-color: white;
    border-radius: 55px;
    line-height: 15px;
    ::-webkit-input-placeholder { line-height: normal; }

    ${theme.breakpoints.down('sm')} {
      padding: 9px 45px 9px 19px;
      line-height: 20px;
    }
  }

  fieldset {
    border-radius: 55px;
  }
  
`

const Submit = styled(Button)`
  width: 35px;
  height: 35px;
  background-color: ${theme.palette.vividBlue};
  border-radius: 30px;
  min-width: auto;
  color: ${theme.palette.white};
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background-color: ${theme.palette.vividBlueHover}
  }

  ${theme.breakpoints.down('sm')} {
    width: 26px;
    height: 26px;
    right: 10px;
  }
`

const SubmitIcon = styled.img`
  width: 15px;
  height: 15px;
  display: block;

  ${theme.breakpoints.down('sm')} {
    width: 12px;
    height: 12px;
  }
`

const Close = styled(Button)`
  width: 35px;
  height: 35px;
  background-color: ${theme.palette.skyBlue};
  border-radius: 30px;
  min-width: auto;
  color: ${theme.palette.white};
  position: absolute;
  right: 25px;
  top: 20px;

  &:hover {
    background-color: ${theme.palette.skyBlueHover}
  }

  ${theme.breakpoints.down('sm')} {
    width: 26px;
    height: 26px;
    right: -2px;
    top: 6px;
  }
`

const Svg = styled(ReactSVG)`
  cursor: pointer;
  svg {
    width: 25px;
    height: 25px;
  }
`

const CloseIcon = styled.img`
  width: 15px;
  height: 15px;
  display: block;

  ${theme.breakpoints.down('sm')} {
    width: 12px;
    height: 12px;
  }
`

const CategorySearch = (props) => {
  const [searchOpen, openSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')
  const [error, setError] = useState(false);

  useEffect(() => {
    props.onActive(!searchOpen)
  },[searchOpen])

  // const fetchSearch = async (string) => {
  //   const q = string.split('+').join(' ');
  //   props.onLoading(true);
  //   let response = await fetch('https://search-jayco-com-au-f4iggop32wn6vb6uqxmer43rhq.ap-southeast-2.es.amazonaws.com/_search?pretty=true&_source=["id"]&size=100&q=' + q)
  //   response = await response.json();
  //   props.searchResults(response);
  //   props.onLoading(false);
  // }

  const handleSubmit = () => {
    if (searchTerm.trim().length > 0) {
      props.fetchSearch(searchTerm.trim())
    } else {
      setError(true)
    }
  }

  const handleChange = event => {
    setSearchTerm(event.target.value);
    if (event.target.value.trim().length > 0) {
      setError(false)
    }
  };

  const handleKeyDown = () => {
    event.key.toLowerCase() === 'enter' && handleSubmit();
  }

  return (
  	<div>
      {searchOpen ?
        <Close onClick={() => openSearch(false)}>
          <CloseIcon src={closeIcon} alt="search"/>
        </Close>
        :
        <Svg src={searchIcon} onClick={() => openSearch(true)}/>
      }
      {searchOpen &&
        <InputContainer>
          <Input
            type="text"
            placeholder={'Search'}
            value={searchTerm}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            variant="outlined"
            type="search"
            // inputRef={ref}
            error={error}
          />
          <Submit onClick={handleSubmit}>
            <SubmitIcon src={searchIcon} alt="search"/>
          </Submit>
        </InputContainer>
      }
    </div>
  )
}
  
CategorySearch.propTypes = {
  searchResults: propTypes.func,
  onLoading: propTypes.func,
  fetchSearch: propTypes.func
}
  
CategorySearch.defaultProps = {
  searchResults: () => {},
  onLoading: () => {},
  fetchSearch: () => {}
}
  
export default CategorySearch